/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Text, Button, Title, ContactForm, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Detail - vzor"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pt--50" style={{"backgroundColor":"#1e1e1f","paddingBottom":40}} name={"novinka"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
              
              <Image className="--shape4" style={{"maxWidth":501}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/23336/ad870507afaa49ee9e8d778395587a88_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/ad870507afaa49ee9e8d778395587a88_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/ad870507afaa49ee9e8d778395587a88_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/ad870507afaa49ee9e8d778395587a88_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/23336/ad870507afaa49ee9e8d778395587a88_s=2000x_.jpg 2000w"} alt={"realizace"} src={"https://cdn.swbpg.com/t/23336/ad870507afaa49ee9e8d778395587a88_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --left" anim={"4"}>
              
              <Subtitle className="subtitle-box subtitle-box--style2 fs--20 w--500 mt--16" content={"<span style=\"color: rgb(199, 199, 199);\">4+kk – České Budějovice<br></span>"}>
              </Subtitle>

              <Text className="text-box fs--14" content={"<span style=\"color: var(--color-custom-1--95);\">In publishing and graphic design, Lorem ipsua placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form of a webpa</span><br>"}>
              </Text>

              <Text className="text-box fs--14" style={{"paddingTop":0,"marginTop":34}} content={"<span style=\"color: var(--color-custom-1--95);\">In publishing and graphic design, Lorem ipsua placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. It is also used to tempora.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4 fs--16" style={{"marginTop":35}} content={"Mám zájem o prohlídku"} use={"url"} href={"#kontakt"} url={"#kontakt"} target={null}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" layout={"l8"} style={{"paddingBottom":3}} name={"q8i71tf9mx"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/1ac7583856bc469ebaff7254772c4aff_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/23336/8a8c7295c885441aa81a7dec13ae99cc_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":89,"paddingBottom":0}} name={"kontakt"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box lh--14" style={{"marginBottom":19,"marginTop":0}} content={"Kontaktní formulář<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":442,"marginTop":0}} content={"Nějaké informace o tom co potřebujete vědět..."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <ContactForm className="--shape3" action={"contact"} name={"9velcwwvqg"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"ndsbszx345p"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"hx6mo2bteq"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"mbhc4li3ha"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"uudb3rmixl8"},{"name":"Odeslat","type":"submit","id":"chakkgeyurq"}]}>
              </ContactForm>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"f0lqvhbqnwn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}